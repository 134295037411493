import * as React from "react"

function Svg(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M125.32 60.0668H173.681C175.82 60.0483 177.94 60.4558 179.919 61.2655C181.898 62.0753 183.697 63.2711 185.209 64.7831C186.721 66.2952 187.916 68.0932 188.726 70.0723C189.536 72.0515 189.943 74.172 189.925 76.3103V124.672C189.921 141.688 183.159 158.007 171.127 170.039C159.094 182.071 142.776 188.833 125.76 188.837H125.395C116.963 188.846 108.612 187.194 100.819 183.975C93.0259 180.756 85.9435 176.032 79.9766 170.075C74.0101 164.116 69.277 157.04 66.048 149.251C62.819 141.462 61.1576 133.112 61.1587 124.68C61.1587 89.1227 89.8378 60.4436 125.324 60.0787L125.32 60.0668Z"
        fill="#25C16F"
      />
      <path
        d="M125.319 88.7461C117.5 88.7461 110 91.8525 104.471 97.3818C98.9413 102.911 95.835 110.411 95.835 118.23V138.278C95.8935 140.86 96.9454 143.32 98.7718 145.147C100.598 146.973 103.058 148.025 105.641 148.084H113.983V125.077H102.717V118.639C103.403 106.513 113.435 97.0325 125.581 97.0325C137.727 97.0325 147.794 106.552 148.445 118.639V125.077H137.033V148.084H144.57V148.449C144.532 150.843 143.564 153.128 141.871 154.821C140.178 156.515 137.892 157.482 135.498 157.52H126.493C125.764 157.52 124.958 157.885 124.958 158.619C124.998 159.412 125.66 160.118 126.493 160.154H135.565C138.664 160.134 141.63 158.895 143.821 156.704C146.012 154.513 147.251 151.547 147.271 148.449V147.719C149.422 147.228 151.341 146.017 152.712 144.288C154.082 142.558 154.821 140.413 154.808 138.207V118.599C155.216 101.987 142.047 88.7461 125.319 88.7461Z"
        fill="white"
      />
    </svg>
  )
}

export default Svg
